import { flattenDeep, isUndefined, omit, omitBy, pick } from 'lodash';
import { CSSProperties } from 'react';
import getBootstrapClassNames from './transformValues';

export type MixedCSS = CSSProperties | undefined | MixedCSS[];
export type MixedClassName = string | undefined | MixedClassName[];
export type ExtendClassName<P = {}> = Omit<P, 'style' | 'className'> & {
  style?: MixedCSS;
  className?: MixedClassName;
};

export type SpacingProps = {
  p?: Bootstrap.Spacer;
  px?: Bootstrap.Spacer;
  py?: Bootstrap.Spacer;
  pt?: Bootstrap.Spacer;
  pr?: Bootstrap.Spacer;
  pb?: Bootstrap.Spacer;
  pl?: Bootstrap.Spacer;
  m?: Bootstrap.Spacer;
  mx?: Bootstrap.Spacer;
  my?: Bootstrap.Spacer;
  mt?: Bootstrap.Spacer;
  mr?: Bootstrap.Spacer;
  mb?: Bootstrap.Spacer;
  ml?: Bootstrap.Spacer;
};

export type FlexProps = {
  center?: boolean;
  centerX?: boolean;
  centerY?: boolean;
  justifyContent?: Bootstrap.JustifyContent;
  alignItems?: Bootstrap.AlignItems;
  display?: Bootstrap.Display;
  direction?: Bootstrap.FlexDirection;
  flex?: Bootstrap.Flex;
};

export type BackgroundProps = {
  bgColor?: Bootstrap.BackgroundColor;
};

export type AllStyleProps = SpacingProps & FlexProps & BackgroundProps;

const defaultClassMap = {
  p: 'p',
  px: 'px',
  py: 'py',
  pt: 'pt',
  pr: 'pe',
  pb: 'pb',
  pl: 'ps',
  m: 'm',
  mx: 'mx',
  my: 'my',
  mt: 'mt',
  mr: 'me',
  mb: 'mb',
  ml: 'ms',
  justifyContent: 'justify-content',
  alignItems: 'align-items',
  display: 'd',
  direction: 'flex',
  flex: 'flex',
  bgColor: 'bg',
};

/**
 * Spread props & style
 */
export function getStyleProps<
  P extends { style?: MixedCSS; className?: MixedClassName }
>(
  allProps: P & AllStyleProps,
  classMap = defaultClassMap
): {
  styleProps: {
    style: CSSProperties;
    className: string;
  };
  otherProps: P;
} {
  const tKeys = Object.keys(classMap);
  const { className, style, center, centerX, centerY, ...otherProps } = omit(
    allProps,
    tKeys
  );
  const classProps = {
    ...pick(allProps, tKeys),
    ...(center && {
      justifyContent: 'center',
      alignItems: 'center',
    }),
    ...(centerX && {
      justifyContent: 'center', // row
    }),
    ...(centerY && {
      alignItems: 'center', // row
    }),
  };

  // array of style to style obj
  const styleArr = flattenDeep<CSSProperties | undefined>([style]);
  const styleObj = styleArr.reduce((prev: CSSProperties, current) => {
    if (current) {
      Object.assign(prev, current);
    }
    return prev;
  }, {});

  // array of className to className string
  const boostrapClassNames = getBootstrapClassNames(classProps, classMap);
  const classNameArr = flattenDeep<string | undefined>([
    boostrapClassNames,
    className,
  ]);
  const classNameStr = classNameArr
    .reduce((prev: string[], current) => {
      if (current) {
        prev.push(current);
      }
      return prev;
    }, [])
    .filter((item, index, items) => items.indexOf(item) === index)
    .join(' ');

  return {
    styleProps: {
      style: styleObj,
      className: classNameStr,
    },
    otherProps: otherProps as P,
  };
}
