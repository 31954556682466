import { ComponentProps, HTMLProps } from 'react';
import createElement from '../utils/createElement';

export type BoxProps = Omit<ComponentProps<typeof Box>, 'wrap'>;
const Box = createElement<HTMLProps<HTMLDivElement>>('div');
export default Box;

export type UlProps = ComponentProps<typeof Ul>;
export const Ul = createElement<HTMLProps<HTMLUListElement>>('ul');

export type LiProps = ComponentProps<typeof Li>;
export const Li = createElement<HTMLProps<HTMLLIElement>>('li');

export type SpanProps = ComponentProps<typeof Span>;
export const Span = createElement<HTMLProps<HTMLLIElement>>('span');

export type LabelProps = ComponentProps<typeof Label>;
export const Label = createElement<HTMLProps<HTMLLabelElement>>('label');

export type AProps = ComponentProps<typeof A>;
export const A = createElement<HTMLProps<HTMLAnchorElement>>('a');
