import React, { FunctionComponent, useMemo } from 'react';
import { AllStyleProps, ExtendClassName, getStyleProps } from './getStyleProps';

/**
 * Create react element with style & className can mixed + style props support
 * @param element
 * @returns
 */
export default function createElement<
  T extends { style?: any; className?: any }
>(element: string | FunctionComponent<T>) {
  return React.forwardRef((props: ExtendClassName<T> & AllStyleProps, ref) => {
    const allProps = useMemo(() => {
      const { styleProps, otherProps } = getStyleProps(props);
      return { ...styleProps, ...otherProps };
    }, [props]);

    return React.createElement(element, {
      ...allProps,
      ref,
    } as any);
  });
}
