import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import SvgLoading from './Icon/SvgLoading';
import {
  AllStyleProps,
  ExtendClassName,
  getStyleProps,
} from '../utils/getStyleProps';

export default function Spinner(props: ExtendClassName & AllStyleProps) {
  const { styleProps } = useMemo(() => {
    return getStyleProps(props);
  }, [props]);

  return (
    <motion.div
      className={styleProps.className}
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '1rem',
        height: '1rem',
        ...styleProps.style,
      }}
      initial={{ rotate: 0 }}
      animate={{
        rotate: 360,
        transition: { repeat: Infinity, ease: 'linear', duration: 1 },
      }}
    >
      <SvgLoading />
    </motion.div>
  );
}
