import { filter, isFunction, isNil, negate, omitBy, uniq } from 'lodash';

/**
 * Helper transform value by valueMap
 * [
 *  [value1, toValue]
 *  [value2, toValue]
 *  ...
 *  [*, () => toValue]
 * ]
 */
function tranformValue<T extends any, D extends any>(
  value: T,
  valueMap: [T | '*', D | ((v: T) => D)][] = []
) {
  for (let vMap of valueMap) {
    if (value === vMap[0] || vMap[0] === '*') {
      return isFunction(vMap[1]) ? vMap[1](value) : vMap[1];
    }
  }
}

/**
 * Current help to transform props value to className
 */
export default function getBootstrapClassNames<T extends Record<string, any>>(
  values: T,
  classMap?: {
    [K in keyof T]?: string;
  }
): string[] {
  const items = Object.entries(omitBy(values, isNil)).map(([key, value]) => {
    const mKey = classMap?.[key];
    return tranformValue<string, string>(value, [['*', (v) => `${mKey}-${v}`]]);
  });
  return uniq(filter(items, negate(isNil))) as string[];
}
