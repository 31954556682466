/* istanbul ignore file */
import { TypePolicies } from '@apollo/client';

const typePolicies: TypePolicies = {
  KeyData: {
    keyFields: ['key'],
  },
  Query: {
    fields: {
      EventPrizeSchedule: {
        merge(existing = [], incoming: any[]) {
          return incoming;
        },
      },
    },
  },
};

export default typePolicies;
