// cloned
import { useCallback, useEffect, useRef, useState } from 'react';

type AnyFunction = (...args: any) => any;
type ResolvedResult<F extends AnyFunction> = {
  loading?: boolean;
  error?: unknown;
  data?: Awaited<ReturnType<F>>;
  called?: number;
};

/**
 * This hook wrap the async function to get state of function error, result, loading
 * also prevent warning for update state on unmount component
 */
export function usePromiseResult<F extends AnyFunction>(fn?: F) {
  const ref = useRef(true);
  const [result, setResult] = useState<ResolvedResult<F>>({
    loading: false,
    called: 0,
  });

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  const run = useCallback(
    async (...args: Parameters<F>) => {
      setResult((prev) => ({
        ...prev,
        loading: true,
      }));
      const nextResult: ResolvedResult<F> = {};
      try {
        if (fn) {
          nextResult.data = await fn(...(args as any));
        } else {
          console.warn('undefined function in usePromiseResult');
        }
      } catch (error) {
        nextResult.error = error;
      }
      nextResult.loading = false;
      if (ref.current) {
        setResult((prev) => ({
          ...nextResult,
          called: (prev.called || 0) + 1,
        }));
      }
      return nextResult;
    },
    [fn]
  );

  return [run, result] as [typeof run, typeof result];
}
