import * as React from 'react';
import { SVGProps } from 'react';
import createElement from '../../utils/createElement';

// check this: https://www.svgrepo.com/_next/static/media/spin.6ffd8bb2.svg
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 392.138 392.138"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
  >
    <path d="M205.264 4.957c-35.732 0-70.47 10.123-100.459 29.274-29.172 18.629-52.578 44.9-67.687 75.971l35.973 17.492c24.816-51.035 75.462-82.738 132.173-82.738 80.987 0 146.874 65.887 146.874 146.874s-65.887 146.874-146.874 146.874c-41.362 0-80.793-17.636-108.46-47.86l-5.844 5.816L0 205.701v181.48l68.44-68.109c14.474 15.535 31.564 28.635 50.335 38.452 26.498 13.856 56.405 21.181 86.489 21.181 103.042 0 186.874-83.831 186.874-186.874S308.306 4.957 205.264 4.957z" />
    <path d="m90.96 296.661 5.844-5.816 85.556-85.144H0z" />
  </svg>
);

export default createElement(SvgComponent);
