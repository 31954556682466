import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import useReactiveVar, { makeVar } from '../../hooks/useReactiveVar';
import Box from '../Box';
import { AnimatePresence, motion } from 'framer-motion';
import getBootstrapClassNames from '../../utils/transformValues';
import Button from '../Button';
import Text from '../Text';
import { usePromiseResult } from '../../hooks/usePromiseResult';
import { noop } from 'lodash';

type ModalVar = {
  isOpen?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'auto';
  title?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  content?: React.ReactNode; // will control render title, body, footer
};
export const modalVar = makeVar<ModalVar>({});

/**
 * Helper to set modal var
 * modal({ body: <ModalBody /> }) // to show modal
 * modal({ isOpen: false }) // to hide modal
 */
export function modal(options: ModalVar) {
  let title = options.title || 'Modal title';
  if (typeof title === 'string') {
    title = <Text weight="semibold">{title}</Text>;
  }
  modalVar((prev) => ({
    isOpen: true,
    body: 'Modal body...',
    ...options,
    title,
  }));
}

/**
 * Quick close the modal
 */
modal.close = () => {
  modalVar((prev) => ({ ...prev, isOpen: false }));
};

type ConfirmFooterProps = {
  onConfirmed?: () => Promise<unknown> | unknown;
  onCancel?: () => void;
};

function ConfirmFooter(props: ConfirmFooterProps) {
  const { onConfirmed = noop, onCancel } = props;
  const [confirm, result] = usePromiseResult(onConfirmed);
  return (
    <>
      <Button onClick={onCancel} color="light">
        No
      </Button>
      <Button onClick={confirm} loading={result.loading}>
        Yes
      </Button>
    </>
  );
}
modal.confirm = (
  options: ModalVar & Pick<ConfirmFooterProps, 'onConfirmed'>
) => {
  const { onConfirmed, ...restOptions } = options;
  return modal({
    title: 'Confirm',
    footer: (
      <ConfirmFooter
        onConfirmed={async () => {
          await onConfirmed?.();
          modal.close();
        }}
        onCancel={modal.close}
      />
    ),
    ...restOptions,
  });
};

modal.success = (options: ModalVar) => {
  return modal({
    title: 'Thành công',
    footer: <Button onClick={modal.close}>OK</Button>,
    ...options,
  });
};

modal.info = (options: ModalVar) => {
  return modal({
    title: 'Thông báo',
    footer: <Button onClick={modal.close}>OK</Button>,
    ...options,
  });
};

modal.error = (options: ModalVar) => {
  return modal({
    title: 'Lỗi',
    footer: <Button onClick={modal.close}>OK</Button>,
    ...options,
  });
};

/**
 * To support modal to show modal
 * Render by modalVar
 * https://getbootstrap.com/docs/5.3/components/modal/
 */
export default function Modal() {
  const {
    title,
    body,
    footer,
    content,
    isOpen,
    size = 'md',
  } = useReactiveVar(modalVar);

  // handle add body class for open state
  useEffect(() => {
    const bodyNode = document.body;
    const baseClassNames = (bodyNode.className || '')
      .split(/\s+/)
      .filter((item) => item && item !== 'modal-open');
    if (isOpen) {
      baseClassNames.push('modal-open');
    }
    bodyNode.className = baseClassNames.join(' ');
  }, [isOpen]);

  const dialogClassName = useMemo(() => {
    return [
      'modal-dialog',
      ...getBootstrapClassNames({ size }, { size: 'modal' }),
    ];
  }, [size]);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="modal-backdrop"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 0.25,
            }}
            exit={{
              opacity: 0,
            }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="modal"
            style={{ display: 'block' }}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                modal.close();
              }
            }}
            tabIndex={-1}
            initial={{
              y: -100,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            exit={{
              y: -100,
              opacity: 0,
              transition: {
                bounce: 0,
              },
            }}
          >
            <Box className={dialogClassName}>
              <Box className="modal-content">
                {!!content && content}
                {!content && (
                  <>
                    <ModalHeader children={title} />
                    <ModalBody children={body} />
                    {!!footer && <ModalFooter children={footer} />}
                  </>
                )}
              </Box>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export function ModalHeader(props: PropsWithChildren<{}>) {
  return (
    <>
      <Box className="modal-header">
        {props.children}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={modal.close}
        ></button>
      </Box>
    </>
  );
}

export function ModalBody(props: PropsWithChildren<{}>) {
  return <Box className="modal-body">{props.children}</Box>;
}

export function ModalFooter(props: PropsWithChildren<{}>) {
  return <Box className="modal-footer">{props.children}</Box>;
}
