import React, { ComponentProps, HTMLProps, forwardRef, useMemo } from 'react';
import createElement from '../utils/createElement';
import Box, { BoxProps } from './Box';
import * as styles from './Button.module.scss';
import Spinner from './Spinner';
import { HStack } from './Stack';

const BaseButton = createElement<HTMLProps<HTMLButtonElement>>('button');
type BaseButtonProps = ComponentProps<typeof BaseButton>;

export type ButtonProps = Omit<BaseButtonProps, 'size'> & {
  size?: 'sm' | 'md' | 'lg';
  color?: Bootstrap.ThemeColor;
  variant?: 'outline'; // default solid
  link?: boolean; // display as link
  loading?: boolean;
  icon?: React.ReactNode;
};

/**
 * https://getbootstrap.com/docs/5.2/components/buttons/#variables
 */
export default forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  props,
  ref
) {
  const {
    size,
    variant,
    color = 'primary',
    link,
    loading,
    children,
    icon,
    ...restProps
  } = props;

  const className = useMemo(() => {
    const result = ['btn'];
    if (size) {
      result.push(
        {
          sm: 'btn-sm',
          md: '',
          lg: 'btn-lg',
        }[size]
      );
    }
    result.push('btn' + (variant ? `-${variant}` : '') + `-${color}`);
    if (link) {
      result.push('btn-link');
    }
    return result;
  }, [size, variant, color, link]);

  return (
    <BaseButton
      ref={ref}
      type="button"
      data-color={color}
      {...restProps}
      className={[className, styles.btn, restProps.className]}
      onClickCapture={(e) => {
        // prevent click on loading state
        loading && e.preventDefault();
      }}
    >
      <Box style={{ visibility: loading ? 'hidden' : 'visible' }}>
        {icon ? (
          <HStack centerY gap="2">
            {icon} {children}
          </HStack>
        ) : (
          children
        )}
      </Box>
      {loading && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      )}
    </BaseButton>
  );
});

export type ButtonListProps = BoxProps;

/**
 * To help list buttons with the same width
 */
export function ActionWrap(props: BoxProps) {
  const { children, className, ...boxProps } = props;
  return (
    <Box {...boxProps} className={[className, styles.actionWrap]}>
      {children}
    </Box>
  );
}
