import React from 'react';
import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from 'gatsby';
import './src/styles/styles.scss';

/**
 * To support charts.css
 */
import 'charts.css';

export default function WrapPageElement({
  element,
  props,
}: WrapPageElementBrowserArgs | WrapPageElementNodeArgs) {
  return <>{element}</>;
}
