exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-change-avatar-tsx": () => import("./../../../src/pages/account/change-avatar.tsx" /* webpackChunkName: "component---src-pages-account-change-avatar-tsx" */),
  "component---src-pages-account-change-email-tsx": () => import("./../../../src/pages/account/change-email.tsx" /* webpackChunkName: "component---src-pages-account-change-email-tsx" */),
  "component---src-pages-account-change-language-tsx": () => import("./../../../src/pages/account/change-language.tsx" /* webpackChunkName: "component---src-pages-account-change-language-tsx" */),
  "component---src-pages-account-change-password-tsx": () => import("./../../../src/pages/account/change-password.tsx" /* webpackChunkName: "component---src-pages-account-change-password-tsx" */),
  "component---src-pages-account-change-phone-tsx": () => import("./../../../src/pages/account/change-phone.tsx" /* webpackChunkName: "component---src-pages-account-change-phone-tsx" */),
  "component---src-pages-account-edit-birthday-tsx": () => import("./../../../src/pages/account/edit-birthday.tsx" /* webpackChunkName: "component---src-pages-account-edit-birthday-tsx" */),
  "component---src-pages-account-edit-gender-tsx": () => import("./../../../src/pages/account/edit-gender.tsx" /* webpackChunkName: "component---src-pages-account-edit-gender-tsx" */),
  "component---src-pages-account-edit-name-tsx": () => import("./../../../src/pages/account/edit-name.tsx" /* webpackChunkName: "component---src-pages-account-edit-name-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-game-tsx": () => import("./../../../src/pages/game/[...].tsx" /* webpackChunkName: "component---src-pages-game-tsx" */),
  "component---src-pages-games-tsx": () => import("./../../../src/pages/games.tsx" /* webpackChunkName: "component---src-pages-games-tsx" */),
  "component---src-pages-home-demo-tsx": () => import("./../../../src/pages/home/demo.tsx" /* webpackChunkName: "component---src-pages-home-demo-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-play-[id]-tsx": () => import("./../../../src/pages/play/[id].tsx" /* webpackChunkName: "component---src-pages-play-[id]-tsx" */),
  "component---src-pages-theme-tsx": () => import("./../../../src/pages/theme.tsx" /* webpackChunkName: "component---src-pages-theme-tsx" */),
  "component---src-pages-ui-index-tsx": () => import("./../../../src/pages/ui/index.tsx" /* webpackChunkName: "component---src-pages-ui-index-tsx" */)
}

