// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
// https://github.com/auth0/jwt-decode
function decode(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// this help dev correct env
const TOKEN_KEY = 'tokens_playwards';

export type TokenProps = {
  accessToken: string;
  userId: string;
};

type HasuraClaimProps = TokenProps & {
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': [string];
    'x-hasura-default-role': string;
    'x-hasura-user-id': string;
    'x-hasura-org-id': string;
    'x-hasura-groups': string;
  };
};

export interface TokenPayloadProps {
  exp: number;
  iat: number;
}

export interface CurrentUserProps {
  id: string;
  role: string;
  roles: Array<string>;
  companyId: string;
  groupIds: Array<string>;
}

/* istanbul ignore next */
export const getTokens = (): TokenProps | null => {
  try {
    const localTokens = localStorage.getItem(TOKEN_KEY) as string;
    return JSON.parse(localTokens);
  } catch (err) {
    return null;
  }
};

/* istanbul ignore next */
export const setTokens = (nextTokens?: TokenProps): void => {
  const currentTokens = getTokens();
  const tokens = nextTokens || currentTokens;
  if (!tokens) {
    return;
  }

  if (currentTokens?.accessToken !== tokens.accessToken) {
    const stringifiedData = JSON.stringify(tokens);
    localStorage.setItem(TOKEN_KEY, stringifiedData);
  }
};

/* istanbul ignore next */
export const removeTokens = (): void => {
  localStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
};

/* istanbul ignore next */
export const isAuthenticated = (tokens = getTokens()): boolean => {
  if (tokens) {
    const { accessToken } = tokens;
    const decoded = decode(accessToken);

    if (decoded) {
      const { exp } = decoded as TokenPayloadProps;
      const timeNow = Math.floor(Date.now() / 1000);

      return timeNow < exp;
    }
  }

  return false;
};

/* istanbul ignore next */
export const getCurrentUserData = (): CurrentUserProps | null => {
  const tokens = getTokens();

  if (tokens) {
    const accessToken = tokens.accessToken;
    const decoded = decode(accessToken) as HasuraClaimProps;
    if (decoded) {
      const userData = decoded['https://hasura.io/jwt/claims'];
      return {
        id: userData['x-hasura-user-id'],
        role: userData['x-hasura-default-role'],
        roles: userData['x-hasura-allowed-roles'],
        companyId: userData['x-hasura-org-id'],
        groupIds: userData['x-hasura-groups']?.replace(/{|\}/g, '').split(','),
      };
    }
  }

  return null;
};

/**
 * Return true if token expired inNext seconds
 * false for expired or invalid token
 */
export const isTokenExpired = (token: string, inNext = 0) => {
  try {
    const decoded = decode(token);
    const exp = decoded.exp;
    const timeNow = Math.floor(Date.now() / 1000);
    if (!exp || timeNow + inNext < exp) {
      throw 'Expired!';
    }
    return true;
  } catch {
    return false;
  }
};

/**
 * Get header authorization tokens
 * use  non expired tokens only
 */
export async function getAuthorizationHeaders(): Promise<{
  authorization?: string;
}> {
  const tokens = getTokens();
  if (tokens && !isTokenExpired(tokens.accessToken)) {
    return {
      authorization: `Bearer ${tokens.accessToken}`,
    };
  }
  return {};
}
