import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

/*
  Error:
  {
    "extensions": {
      "path": "$",
      "code": "constraint-violation"
    },
    "message": "Uniqueness violation. duplicate key value violates unique constraint \"Users_email_key\""
  }
*/
function getGraphQLErrorMessage(error: GraphQLError) {
  const { message, extensions } = error;
  const isConstraintViolation = extensions?.code === 'constraint-violation';
  const isValidationFailed = extensions?.code === 'validation-failed';

  if (isConstraintViolation) {
    if (message.includes('Users_email_key')) {
      return 'common:errors.userExists';
    } else if (message.includes('CompanyId_phone_key')) {
      return 'common:errors.customerExists';
    }
  } else if (isValidationFailed) {
    return 'Validation Failed';
  }
  return message;
}

type HandleError = ApolloError | any;

export default function parseError(error: HandleError) {
  const result = {
    message: 'Unknown error',
    error,
  };

  if (error.networkError) {
    result.message = 'Network error';
  }

  if (error.graphQLErrors) {
    result.message = error.graphQLErrors
      .map((item: GraphQLError) => {
        return getGraphQLErrorMessage(item);
      })
      .join(', ');
  }

  return result;
}
