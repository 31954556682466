import { omit, pick } from 'lodash';
import React, { useMemo } from 'react';
import getBootstrapClassNames from '../utils/transformValues';
import Box, { A, BoxProps, Label, Span } from './Box';

export type TextProps = Omit<BoxProps, 'size'> & {
  font?: Bootstrap.Font;
  color?: Bootstrap.TextColor;
  size?: Bootstrap.FontSize;
  type?: Bootstrap.FontType;
  weight?: Bootstrap.FontWeight;
  transform?: Bootstrap.TextTransform;
  decoration?: Bootstrap.TextDecoration;
  align?: Bootstrap.TextAlign;
  lineHeight?: Bootstrap.LineHeight;
  nowrap?: boolean;
  as?: 'a' | 'span' | 'label';
};

export default function Text(props: TextProps) {
  const [boxProps, classed, C] = useMemo(() => {
    const transformValueOptions = {
      font: 'font',
      color: 'text',
      size: 'fs',
      type: 'fsw',
      weight: 'fw',
      transform: 'text',
      whiteSpace: 'text',
      decoration: 'text-decoration',
      align: 'text',
      lineHeight: 'lh',
    };
    const tKeys = Object.keys(transformValueOptions);
    const { as, nowrap, ...boxProps } = omit(props, tKeys);
    return [
      boxProps,
      getBootstrapClassNames(
        pick(
          {
            ...props,
            ...(nowrap && { whiteSpace: 'nowrap' }),
          },
          tKeys
        ),
        transformValueOptions
      ),
      as ? (as === 'a' ? A : as === 'label' ? Label : Span) : Box,
    ];
  }, [props]);
  return React.createElement(C as any, {
    ...boxProps,
    className: [classed, boxProps.className],
  });
}
