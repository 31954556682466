import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby';
import { client } from './src/apollo';

export default function WrapRootElement({
  element,
}: WrapRootElementBrowserArgs | WrapRootElementNodeArgs) {
  return <ApolloProvider client={client}>{element}</ApolloProvider>;
}
