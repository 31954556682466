import { ApolloClient, split, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { get } from 'lodash';
import site from '../sites';
import WebSocketLink from './WebSocketLink';
import typePolicies from './typePolicies';
import parseError from '../utils/parseError';
import { getTokens, isAuthenticated } from '../utils/user';
import { tokensVar } from '../hooks/useTokens';
import { modal } from '../components/Layout/Modal';

/**
 * The apollo client load on open app (import by index), but the app maybe not config
 * so to prevent throw error, we get graphql config safely
 */
const graphqlUrl = get(site, 'graphql.url', '');
const graphqlWs = get(site, 'graphql.ws', '');

const errorLink = onError((error) => {
  const parsed = parseError(error);
  console.error(parsed.message);
  modal.error({
    title: 'Request Error',
    body: parsed.message,
  });
});

/**
 * Get header authorization tokens form localStorage
 */
function getAuthorizationHeaders(): Partial<{ authorization: string }> {
  const tokens = getTokens();
  if (tokens && isAuthenticated(tokens)) {
    return {
      authorization: `Bearer ${tokens.accessToken}`,
    };
  }
  return {};
}

const isSSR = typeof window === 'undefined';

const wsLink = isSSR
  ? null
  : new WebSocketLink({
      url: graphqlWs,
      connectionParams: () => {
        return {
          headers: getAuthorizationHeaders(),
        };
      },
    });
const batchLink = new BatchHttpLink({
  uri: graphqlUrl,
  fetch: (uri, opts) => {
    return window.fetch(uri, {
      ...opts,
      headers: {
        ...opts?.headers,
        ...getAuthorizationHeaders(),
      },
    } as RequestInit);
  },
  batchMax: 10,
});

// https://www.apollographql.com/docs/react/data/subscriptions/
const link = isSSR
  ? from([errorLink, batchLink])
  : split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      from([errorLink, wsLink as any]),
      from([errorLink, batchLink])
    );
const cache = new InMemoryCache({
  typePolicies,
});

export const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

tokensVar.subscribe(function (value) {
  if (!value.tokens) {
    console.info('Trigger clear store');
    client.clearStore();
  }
});
