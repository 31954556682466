import { useCallback, useEffect } from 'react';
import {
  getTokens,
  TokenProps,
  setTokens as setLocalTokens,
  removeTokens as removeLocalTokens,
  isTokenExpired,
} from '../utils/user';
import useReactiveVar, { makeVar } from './useReactiveVar';

type TokensVar = {
  tokens: TokenProps | null;
  isLocal?: boolean; // this tokens load from local
};

const localTokens = getTokens();
export const tokensVar = makeVar<TokensVar>({
  tokens:
    localTokens?.accessToken && !isTokenExpired(localTokens.accessToken)
      ? localTokens
      : null,
  isLocal: true,
});

/**
 * Hook to handle tokens set/remove
 */
export default function useTokens() {
  const data = useReactiveVar(tokensVar);

  // state tokens -> local tokens
  useEffect(() => {
    if (data?.tokens) {
      setLocalTokens(data.tokens);
    } else {
      removeLocalTokens();
    }
  }, [data?.tokens]);

  const setHookTokens = useCallback((tokens: TokenProps) => {
    tokensVar({ tokens, isLocal: false });
  }, []);

  const removeHookTokens = useCallback(() => {
    tokensVar({ tokens: null, isLocal: false });
  }, []);

  return {
    data,
    setTokens: setHookTokens,
    removeTokens: removeHookTokens,
    isUserLogged: !!data?.tokens?.userId,
  };
}
