const config = {
  api: {
    url: 'https://dp-be.vn09.com',
    ws: 'https://dp-be.vn09.com',
    // url: 'http://localhost:3000',
    // ws: 'http://localhost:3000',
  },
  graphql: {
    api: 'https://dp-gql.vn09.com/v1',
    url: 'https://dp-gql.vn09.com/v1/graphql',
    ws: 'wss://dp-gql.vn09.com/v1/graphql',
  },
  media: {
    // uploadUrl: 'https://dp-be.vn09.com/file/upload',
    // baseUrl: 'https://dp-be.vn09.com/file/image',
    baseUrl: 'https://media.depire.com',
  },
};

export default config;
