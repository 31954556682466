import React, { useMemo } from 'react';
import getBootstrapClassNames from '../utils/transformValues';
import Box, { BoxProps } from './Box';

export type StackProps = BoxProps & {
  gap?: Bootstrap.Spacer;
  wrap?: Bootstrap.FlexWrap;
};

/**
 * https://chakra-ui.com/docs/components/stack
 * https://ant.design/components/space
 * https://getbootstrap.com/docs/5.2/helpers/stacks
 */
const Stack: React.FC<StackProps> = (props: StackProps) => {
  const { gap = 3, wrap, ...restProps } = props;

  const className = useMemo(() => {
    return [
      ...getBootstrapClassNames({ gap, wrap }, { gap: 'gap', wrap: 'flex' }),
      props.className,
    ];
  }, [gap, wrap, props.className]);

  return (
    <Box {...restProps} display="flex" className={className}>
      {props.children}
    </Box>
  );
};

export default Stack;

export const VStack = (props: StackProps) => {
  return <Stack direction="column" {...props} />;
};

export const HStack = (props: StackProps) => {
  return <Stack direction="row" {...props} />;
};
